<template>
  <div class="w-full flex justify-center items-center h-full">
    <div class="w-full">
      <button
        data-v-step="0"
        @click="startAssessment"
        class="h-44 w-44 animation-pulse bg-white circle pulse textcol text-xl flex items-center justify-center mx-auto"
      >
        Sınavı Başlat
      </button>
      <p
        class="mt-10 w-8/12 mx-auto text-gray-300 text-base max-sm:w-10/12 text-center"
      >
        <span class="text-lg font-semibold underline">NOT:</span>
        Sınavın başlayabilmesi için cihazınızın
        <b>sesinin açık olduğuna emin olun</b>
      </p>
      <div class="flex items-center justify-center pt-5 text-xs gap-3">
        <input
          v-model="$store.state.consentStatement.isValid"
          type="checkbox"
          class="w-5 h-5 cursor-pointer"
          @change="changeIsValid"
        />
        <a
          class="text-white underline"
          :href="$store.state.consentStatement.url"
          target="_blank"
          >Açık Rıza Beyanını onaylıyorum.</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "start-btn",
  data() {
    return {
      isValid:
        //   this.$store.state.consentStatement.isValid
        false,
    };
  },
  methods: {
    startAssessment() {
      if (this.$store.state.consentStatement.isValid) {
        this.$router.push("/assessment/exam");
        this.$store.dispatch("setTimerLength", 30);
        this.$store.dispatch("startTimer", 30 * 60);
      } else {
        this.authController("Lütfen Açık Rıza Beyanını onaylayın.");
      }
    },
    changeIsValid() {
      this.isValid = !this.isValid;
      //   this.$store.commit("changeIsValid", this.isValid);
    },
  },
};
</script>
