<template>
  <div class="flex justify-center w-full min-h-screen-24">
    <div class="flex flex-col items-center justify-center space-y-4">
      <img
        :src="getImage"
        :alt="getText"
        class="rounded-full max-w-[260px] h-auto"
      />
      <p
        class=" w-8/12 mx-auto text-gray-300 text-center font-semibold text-base"
      >
        {{ getText }}
      </p>
      <p v-if="isProcessDone" class="text-xl text-gray-50">
        İşleminiz bitti. Artık sayfayı kapatabilirsiniz.
      </p>
      <p v-else class="text-base text-gray-50">
        Lütfen daha sonra tekrar deneyiniz.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "end-of-process",
  data() {
    return {
      fail: false,
    };
  },
  computed: {
    dateSelected() {
      return this.$store.state.dateSelected;
    },
    interviewDone() {
      return this.$store.state.interviewDone;
    },
    getImage() {
      if (this.dateSelected) {
        return require("@/assets/interview/success.gif");
      } else if (this.interviewDone) {
        return require("@/assets/interview/success.gif");
      } else {
        return require("@/assets/interview/failure.gif");
      }
    },
    getText() {
      if (this.dateSelected) {
        return "Zaman ayırdığınız için teşekkür ederim. Tarih seçiminiz alındı ve en kısa sürede size dönüş yapılacak. İyi günler!";
      } else if (this.interviewDone) {
        return "Ön Görüşme gerçekleştirildi.";
      } else {
        return "Bir hata oluştu";
      }
    },
    isProcessDone() {
      return this.dateSelected || this.interviewDone;
    },
  },
};
</script>
