<template>
  <!-- Düğme bileşeni -->
  <div class="h-28 w-28 max-lg:h-24 max-lg:w-24">
    <transition name="fade">
      <button
        :data-v-step="step"
        v-show="!disabled"
        @mousedown="isHoldAndSpeak && startRecording()"
        @mouseup="isHoldAndSpeak && stopRecording()"
        @click="!isHoldAndSpeak && activeProccess()"
        :disabled="disabled"
        :class="['text-lg custom-btn ', buttonClass]"
      >
        <!-- Mikrofon ikonu -->
        <span v-if="step == 0 && !disabled && !activeRecord">
          <i class="fa-solid fa-microphone text-xl"></i>
        </span>

        <!-- Ses kaydedilirken gösterilen canvas -->
        <span v-else-if="step == 0 && !disabled && activeRecord">
          <canvas ref="canvas" height="100%" width="100%"></canvas>
        </span>

        <!-- İşlem devam ederken gösterilen dönen ikon -->
        <span v-else-if="!disabled && step == 1">
          <i class="fa-solid fa-spinner fa-spin text-4xl"></i>
        </span>
      </button>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "action-btn",
  props: ["disabled"],

  data() {
    return {
      step: 0,
      activeRecord: false,
      audioContext: null,
      analyser: null,
      dataArray: null,
      animationFrameId: null,
      isButtonPressed: false,
    };
  },

  computed: {
    ...mapState(["micActiveType"]),
    buttonClass() {
      return this.activeRecord ? "pulse circle bg-white/10" : "";
    },
    isHoldAndSpeak() {
      return this.micActiveType === 2;
    },
  },

  mounted() {
    window.addEventListener("mouseup", this.stopRecording);
  },
  beforeDestroy() {
    window.removeEventListener("mouseup", this.stopRecording);
  },

  methods: {
    reset() {
      this.step = 0;
      this.activeRecord = false;
    },

    async requestMicrophonePermission() {
      if (!this.permissionGranted) {
        await navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: false,
          })
          .then(() => {
            this.permissionGranted = true;
          })
          .catch(() => {
            this.authController("Lütfen mikrofon iznini onaylayın.");
          });
      }
    },

    async startRecordingProcess() {
      this.$emit("isRecord", true);
      this.activeRecord = true;
      await this.requestMicrophonePermission();
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          this.audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          const source = this.audioContext.createMediaStreamSource(stream);
          this.analyser = this.audioContext.createAnalyser();
          source.connect(this.analyser);
          this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
          this.analyseAudio();
        });
    },

    stopRecordingProcess() {
      this.$emit("isRecord", false);
      this.activeRecord = false;
      cancelAnimationFrame(this.animationFrameId);
      if (this.audioContext) {
        this.audioContext.close();
      }
      this.audioContext = null;
      this.analyser = null;
      this.dataArray = null;
      this.step++;
    },

    async startRecording() {
      if (!this.activeRecord && this.isHoldAndSpeak) {
        await this.startRecordingProcess();
      }
    },

    stopRecording() {
      if (this.activeRecord && this.isHoldAndSpeak) {
        this.stopRecordingProcess();
      }
    },

    analyseAudio() {
      if (this.analyser) {
        this.analyser.getByteFrequencyData(this.dataArray);
        const volume =
          this.dataArray.reduce((a, b) => a + b) / this.dataArray.length;

        this.drawOnCanvas(volume);

        this.animationFrameId = requestAnimationFrame(this.analyseAudio);
      }
    },

    // Ses seviyesine göre canvas'a çizer
    drawOnCanvas(volume) {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");

      // Canvas'ı temizle
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Canvas genişliğini ve yüksekliğini aynı yap
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      // Kutu özelliklerini tanımla
      const boxCount = 5;
      const boxSize = 8;
      const gutter = 6;
      const baseY = canvas.height / 2;

      // Her kutu için maksimum yükseklikleri tanımla
      const maxHeights = [
        canvas.height * 0.6,
        canvas.height * 1,
        canvas.height * 0.7,
        canvas.height * 1,
        canvas.height * 0.6,
      ];

      // Her kutu için rastgele bir yükseklik belirle
      const scaleY = [];
      const randomFactors = this.randomFactors || Array(boxCount).fill(1);
      const speeds = this.speeds || Array(boxCount).fill(0);
      for (let i = 0; i < boxCount; i++) {
        randomFactors[i] = 0.5 + Math.random() * 0.5;
        const targetHeight = maxHeights[i] * randomFactors[i] * (volume / 100);
        if (speeds[i] < targetHeight) {
          speeds[i] += 1.8; // Yükselme hızını belirle
        } else {
          speeds[i] -= 0.7; // Düşme hızını belirle
        }
        scaleY[i] = speeds[i];
      }
      this.randomFactors = randomFactors;
      this.speeds = speeds;

      // Tüm kutuların toplam genişliğini hesapla
      const totalBoxWidth = boxCount * boxSize + (boxCount - 1) * gutter;

      // Kutuları çiz
      const startX = (canvas.width - totalBoxWidth) / 2;
      for (let i = 0; i < boxCount; i++) {
        const x = startX + i * (boxSize + gutter);

        // Yüksekliği hesapla
        const height = Math.min(maxHeights[i], scaleY[i]);

        // Kutuyu çiz
        ctx.fillStyle = "white";
        ctx.fillRect(x, baseY - height / 2, boxSize, height);
      }
    },

    // Aktif süreci yönetir
    async activeProccess() {
      await this.requestMicrophonePermission();

      if (!this.activeRecord && !this.isHoldAndSpeak) {
        await this.startRecordingProcess();
      } else if (this.activeRecord) {
        this.stopRecordingProcess();
      }
    },
  },
};
</script>

<style scoped>
.custom-btn {
  @apply h-28 w-28  max-lg:h-24 max-lg:w-24 border border-white text-white  transition-all ease-out rounded-full flex items-center justify-center mx-auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.circle {
  border-radius: 50%;
  box-shadow: 0px 0px 3px 3px #0000001a;
}
.pulse {
  animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>
