<template>
  <div class="h-11" v-if="false">
    <div v-if="botSepeak" class="boxContainer h-11 mx-auto">
      <div class="box" :class="botSepeak ? 'box1' : 'hidden'"></div>
      <div class="box" :class="botSepeak ? 'box2' : 'hidden'"></div>
      <div class="box" :class="botSepeak ? 'box3' : 'hidden'"></div>
      <div class="box" :class="botSepeak ? 'box4' : 'hidden'"></div>
      <div class="box" :class="botSepeak ? 'box5' : 'hidden'"></div>
    </div>
    <div
      v-if="speechReady"
      class="flex justify-center items-center text-white w-full h-11"
    >
      <i class="fa-solid fa-spinner animate-spin text-3xl p-3"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "speech-animation",
  props: ["botSepeak", "load", "speechReady"],
};
</script>
<style scoped>
@keyframes quiet {
  25% {
    transform: scaleY(0.6);
  }

  50% {
    transform: scaleY(0.4);
  }

  75% {
    transform: scaleY(0.8);
  }
}

@keyframes normal {
  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.4);
  }

  75% {
    transform: scaleY(0.6);
  }
}

@keyframes loud {
  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.4);
  }

  75% {
    transform: scaleY(1.2);
  }
}

.boxContainer {
  display: flex;
  justify-content: space-between;
  --boxSize: 3.8px;
  --gutter: 6px;
  width: calc((var(--boxSize) + var(--gutter)) * 5.5);
}

.box {
  transform: scaleY(0.4);
  height: 100%;
  width: var(--boxSize);
  background: white;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 6px;
}

.box1 {
  animation-name: quiet;
}

.box2 {
  animation-name: normal;
}

.box3 {
  animation-name: quiet;
}

.box4 {
  animation-name: loud;
}

.box5 {
  animation-name: quiet;
}
</style>
