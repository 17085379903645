<template>
  <div class="flex justify-between w-full p-2 sm:p-4">
    <div class="flex space-x-2 sm:space-x-10">
      <button
        @click="decrementQuestionIndex"
        :disabled="questionIndex === 0"
        class="outline outline-1 font-bold py-1 sm:py-2 px-2 sm:px-3 rounded-lg bg-transparent hover:bg-gray-700 text-white"
      >
        <i class="fas fa-arrow-left"></i>
      </button>

      <div class="w-full no-scrollbar">
        <div class="flex items-center space-x-2 sm:space-x-3">
          <button
            v-for="i in questionCount"
            :key="i"
            @click="$emit('change-question-index', i - 1)"
            class="outline outline-1 font-bold py-1 sm:py-2 px-2 sm:px-4 rounded-lg bg-transparent hover:bg-gray-700 text-white"
          >
            {{ i }}
          </button>
        </div>
      </div>
    </div>

    <button
      @click="incrementQuestionIndex"
      :disabled="questionIndex === questionCount - 1"
      class="outline outline-1 font-bold py-1 sm:py-2 px-2 sm:px-3 rounded-lg bg-transparent hover:bg-gray-700 text-white"
    >
      <i class="fas fa-arrow-right"></i>
    </button>
  </div>
</template>

<style scoped>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>

<script>
export default {
  components: {},
  props: {
    questionCount: {
      type: Number,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
    changeActiveQuestion: {
      type: Function,
      required: true,
    },
  },
  methods: {
    incrementQuestionIndex() {
      if (this.questionIndex < this.questionCount - 1) {
        this.$emit("change-question-index", this.questionIndex + 1);
      }
    },
    decrementQuestionIndex() {
      if (this.questionIndex > 0) {
        this.$emit("change-question-index", this.questionIndex - 1);
      }
    },
  },
  updated() {
    console.log(this.questionIndex);
  },
};
</script>
