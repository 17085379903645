<template>
  <div class="flex flex-col items-center justify-between h-full">
    <div>
      <QuestionsComponent
        :question="activeExam.questions[activeQuestionIndex]"
        :question-index="activeQuestionIndex"
        @answer-submitted="handleAnswerSubmitted"
      />
    </div>
  </div>
</template>
<script>
import QuestionsComponent from "../../../components/questionsComponent.vue";
export default {
  name: "grammarExam",
  components: {
    QuestionsComponent,
  },
  props: {
    steps: {
      type: Number,
      default: 0,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
    activeExam: {
      type: Object,
      default: () => {},
    },
    activeQuestionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      answers: [],
    };
  },
  methods: {
    handleAnswerSubmitted({ examName, answer, questionIndex }) {
      this.$emit("answer-submitted", { examName, answer, questionIndex });
    },
  },
};
</script>
