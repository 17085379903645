import { render, staticRenderFns } from "./speechAnimation.vue?vue&type=template&id=84cda660&scoped=true&"
import script from "./speechAnimation.vue?vue&type=script&lang=js&"
export * from "./speechAnimation.vue?vue&type=script&lang=js&"
import style0 from "./speechAnimation.vue?vue&type=style&index=0&id=84cda660&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84cda660",
  null
  
)

export default component.exports