<template>
  <div class="flex justify-center items-center flex-wrap relative">
    <div class="w-full mx-auto">
      <div class="w-[55%] mx-auto -mt-14 max-md:w-[90%]">
        <div class="w-full">
          <img
            src="@/assets/cvus.svg"
            class="w-full h-full flex object-cover items-center"
            alt=""
          />
        </div>
      </div>

      <div class="w-full absolute mt-8">
        <p class="font-bold text-white text-2xl text-center">Uyarı:</p>
        <p class="block w-full text-lg font-light text-gray-100 text-center">
          Böyle bir ön görüşme yok yada önceden tamamlandı
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "interview-err",
  components: {},
};
</script>

<!-- Yaptığınız işlemler başarı ile gerçekleşti. Bu sayfayı
          kapatabilirsiniz. Yakın zamanda sizinle iletişime geçeceğiz. Lütfen
          maillerinizi kontrol edin. -->
