<template>
  <div
    class="px-4 sm:px-6 md:px-8 lg:px-10 pt-10 flex flex-col justify-between text-white min-h-screen-24"
  >
    <!-- Top Div Current Exam -->
    <div class="flex items-center sm:flex-row">
      <div class="flex items-center space-x-2 sm:space-x-4 md:space-x-6">
        <img
          :src="activeSvg.svg"
          class="w-[70px] sm:w-[75px] md:w-[95px] p-2 sm:p-3 md:p-4 mr-1 sm:mr-2 md:mr-2 border-2 rounded-lg"
          alt="Exam Icon"
        />
        <span class="text-xl sm:text-2xl md:text-3xl font-bold text-white">
          {{ activeExamName }}
        </span>
      </div>
      <ul class="h-full overflow-auto flex items-center mt-2 ml-5 sm:hidden">
        <li
          v-for="(svg, index) in svgList"
          :key="index"
          :class="{ active: svg.name === activeExam.svgName }"
          class="text-lg sm:text-xl md:text-xl p-1 ml-1 sm:ml-2 md:ml-4 rounded-lg"
        >
          <span class="text-white">
            <img
              :src="svg.svg"
              alt="Exam Icon"
              class="border-2 border-white rounded-xl p-1 w-[40px]"
              :style="{
                backgroundColor:
                  svg.name === activeExam.svgName ? '#2D8CFF40' : '',
              }"
            />
          </span>
        </li>
      </ul>
    </div>

    <!-- Svg Icons and Dynamic Component -->
    <div class="grid grid-cols-6 mt-3 sm:mt-4 md:mt-5 h-3/4 flex-grow">
      <div class="col-span-1 hidden sm:inline-block">
        <ul class="flex flex-col h-full overflow-auto">
          <li
            v-for="(svg, index) in svgList"
            :key="index"
            :class="{ active: svg.name === activeExam.svgName }"
            @click="changeActiveExam(svg.name)"
            class="text-lg sm:text-xl md:text-xl p-1 sm:p-2 md:p-2 ml-2 sm:ml-3 md:ml-4 rounded-lg"
          >
            <span class="text-white">
              <img
                :src="svg.svg"
                alt="Exam Icon"
                class="border-2 border-white rounded-xl p-1 w-[30px] sm:w-[35px] md:w-[42px]"
                :style="{
                  backgroundColor:
                    svg.name === activeExam.svgName ? '#2D8CFF40' : '',
                }"
              />
            </span>
          </li>
        </ul>
      </div>

      <div
        class="col-span-6 sm:col-span-5 md:col-span-5 lg:col-span-4 flex-auto h-full"
      >
        <component
          :ref="activeExam.component"
          class="h-full w-full sm:h-auto sm:w-auto transition-all ease-in-out"
          :is="activeExam.component"
          :steps="activeExam.steps"
          :currentStep="activeStep"
          :activeQuestionIndex="activeQuestionIndex"
          :activeExam="activeExam"
          @answer-submitted="handleAnswerSubmitted"
        ></component>
      </div>
    </div>

    <!-- Bottom Div -->
    <div class="p-2 flex flex-col justify-between space-y-3">
      <QuestionsPagination
        v-if="paginationShow"
        :questionCount="currentExamQuestionCount"
        :questionIndex="activeQuestionIndex"
        @change-question-index="changeActiveQuestion"
        :changeActiveQuestion="changeActiveQuestion"
      />

      <div class="flex justify-between">
        <div @click="toggleDarkMode">
          <img
            src="@/assets/assestments/icons/dark-mode.svg"
            alt="Dark and Light"
            class="border-2 border-white rounded-xl p-1 w-8 sm:w-9 md:w-10 bg-[#2D8CFF40] ml-2 sm:ml-3 md:ml-4"
          />
        </div>

        <div
          class="w-full justify-center items-center space-y-3 sm:space-y-4 md:space-y-5"
        >
          <div
            class="h-px bg-gradient-to-r from-transparent via-white to-transparent"
          />
          <div class="flex justify-between w-full">
            <div />
            <p
              class="text-xs sm:text-sm md:text-base text-white text-center w-3/4"
            >
              <span class="font-semibold">Not:</span>
              {{ this.activeNote.note }}
            </p>
            <asyncButton
              button-class="w-full sm:w-auto hover:bg-blue-600/50
            text-white font-bold py-2 px-4 "
              @save="this.handleClick"
              :text="
                activeStep === activeExam.steps
                  ? 'Sonraki Sınav'
                  : 'Sorulara Geç'
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ListeningExam from "./components/listeningExam/index.vue";
import GrammarExam from "./components/grammarExam/index.vue";
import VocabularyExam from "./components/vocabularyExam/index.vue";
import WritingExam from "./components/writingExam/index.vue";
import SpeakingExam from "./components/speakingExam/index.vue";
import ReadingExam from "./components/readingExam/index.vue";
import QuestionsPagination from "../components/questionsPagination.vue";

import asyncButton from "@/components/button.vue";

import ListeningSvg from "@/assets/assestments/icons/listening-systems.svg";
import PencilLineSvg from "@/assets/assestments/icons/pencil-line.svg";
import JamMic from "@/assets/assestments/icons/jam_mic-alt.svg";
import BookOpenSvg from "@/assets/assestments/icons/book.svg";

export default {
  name: "ExamLayout",
  components: {
    ListeningExam,
    GrammarExam,
    VocabularyExam,
    WritingExam,
    SpeakingExam,
    ReadingExam,
    asyncButton,
    QuestionsPagination,
  },
  data() {
    return {
      exams: [
        {
          name: "Listening",
          component: ListeningExam,
          svgName: "listening",
          steps: 2,
          completed: false,
          questions: [
            {
              id: 1,
              question: "Soru buraya gelecek",
              answers: ["Cevap 1", "Cevap 2", "Cevap 3", "Cevap 4"],
            },
            {
              id: 2,
              question: "Soru 2 buraya gelecek",
              answers: ["Cevap 1", "Cevap 2", "Cevap 3", "Cevap 4"],
            },
            {
              id: 3,
              question: "Soru 3 buraya gelecek",
              answers: ["Cevap 1", "Cevap 2", "Cevap 3", "Cevap 4"],
            },
            {
              id: 4,
              question: "Soru 4 buraya gelecek",
              answers: ["Cevap 1", "Cevap 2", "Cevap 3", "Cevap 4"],
            },
            {
              id: 5,
              question: "Soru 5 buraya gelecek",
              answers: ["Cevap 1", "Cevap 2", "Cevap 3", "Cevap 4"],
            },
          ],
        },
        {
          name: "Grammar",
          component: GrammarExam,
          svgName: "listening",
          steps: 1,
          completed: false,
          questions: [
            {
              id: 1,
              question: "Soru 1 buraya gelecek",
              answers: ["Cevap 1", "Cevap 2", "Cevap 3", "Cevap 4"],
            },
            {
              id: 2,
              question: "Soru 2 buraya gelecek",
              answers: ["Cevap 1", "Cevap 2", "Cevap 3", "Cevap 4"],
            },
            {
              id: 3,
              question: "Soru 3 buraya gelecek 2",
              answers: ["Cevap 1", "Cevap 2", "Cevap 3", "Cevap 4"],
            },
            {
              id: 4,
              question: "Soru 4 buraya gelecek 3",
              answers: ["Cevap 1", "Cevap 2", "Cevap 3", "Cevap 4"],
            },
          ],
        },
        {
          name: "Vocabulary",
          component: VocabularyExam,
          svgName: "grammar",
          steps: 1,
          completed: false,
          questions: [
            {
              id: 1,
              question: "",
              answers: "",
            },
            {
              id: 2,
              question: "",
              answers: "",
            },
            {
              id: 3,
              question: "",
              answers: "",
            },
          ],
        },
        {
          name: "Writing",
          component: WritingExam,
          svgName: "writing",
          steps: 1,
          completed: false,
          questions: [
            {
              id: 1,
              question: "Soru 1 buraya gelecek",
              answers: "",
            },
          ],
        },
        {
          name: "Speaking",
          component: SpeakingExam,
          svgName: "speaking",
          steps: 1,
          completed: false,
          questions: [
            {
              id: 1,
              question: "Soru 1 buraya gelecek",
              answers: "",
            },
          ],
        },
        {
          name: "Reading",
          component: ReadingExam,
          svgName: "writing", //şimdilik
          steps: 2,
          completed: false,
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed accumsan justo ac metus fermentum, vel sollicitudin sem pharetra. Integer hendrerit, augue vel tincidunt fermentum, dui odio tincidunt odio, in aliquam lectus arcu ut elit. Vestibulum eu convallis ex. In hac habitasse platea dictumst. Sed commodo nulla vel velit rhoncus, eu tincidunt justo fermentum. Nulla facilisi. Ut condimentum hendrerit justo, nec fringilla erat hendrerit vel.",
          questions: [
            {
              id: 1,
              question: "Soru 1 buraya gelecek",
              answers: ["Cevap 1", "Cevap 2", "Cevap 3", "Cevap 4"],
            },
            {
              id: 2,
              question: "Soru 2 buraya gelecek",
              answers: ["Cevap 1", "Cevap 2", "Cevap 3", "Cevap 4"],
            },
          ],
        },
      ],
      svgList: [
        { name: "listening", svg: ListeningSvg },
        { name: "grammar", svg: PencilLineSvg },
        { name: "speaking", svg: JamMic },
        { name: "writing", svg: BookOpenSvg },
      ],
      tempAnswer: {
        Listening: [],
        Grammar: [],
        Vocabulary: [],
        Writing: [],
        Speaking: [],
        Reading: [],
      },
      notes: [
        {
          name: "Listening",
          note: "Cevaplamak istediğinizde, ses kaydını dinleyip sonra ki adıma geçeyim , sesinizin karşılığı olarak metin alanından cevabınızı görebilirsiniz.",
        },
        {
          name: "Grammar",
          note: "Cevaplamak istediğinizde, ses kaydını dinleyip sonra ki adıma geçeyim , sesinizin karşılığı olarak metin alanından cevabınızı görebilirsiniz.",
        },
        {
          name: "Speaking",
          note: "Cevaplamak istediğinizde, ses kaydını dinleyip sonra ki adıma geçeyim , sesinizin karşılığı olarak metin alanından cevabınızı görebilirsiniz.",
        },
        {
          name: "Vocabulary",
          note: "Cevaplamak istediğinizde, ses kaydını dinleyip sonra ki adıma geçeyim , sesinizin karşılığı olarak metin alanından cevabınızı görebilirsiniz.",
        },
        {
          name: "Writing",
          note: "Cevaplamak istediğinizde, ses kaydını dinleyip sonra ki adıma geçeyim , sesinizin karşılığı olarak metin alanından cevabınızı görebilirsiniz.",
        },
        {
          name: "Reading",
          note: "Cevaplamak istediğinizde, ses kaydını dinleyip sonra ki adıma geçeyim , sesinizin karşılığı olarak metin alanından cevabınızı görebilirsiniz.",
        },
      ],
      paginationShow: false,
    };
  },
  methods: {
    handleClick() {
      this.startNextStep();
    },
    changeActiveQuestion(newQuestionIndex) {
      if (
        newQuestionIndex >= 0 &&
        newQuestionIndex < this.currentExamQuestionCount
      ) {
        this.$store.commit("setActiveQuestionIndex", newQuestionIndex);
        const newActiveQuestion = this.activeExam.questions[newQuestionIndex];
        this.$store.commit("setActiveQuestion", newActiveQuestion);
      }
    },
    areAllStepsCompleted(examName) {
      const exam = this.exams.find((exam) => exam.name === examName);
      if (exam) {
        return this.activeStep === exam.steps;
      }
      return false;
    },
    changeActiveExam(newExamName) {
      const newActiveExam = this.exams.find(
        (exam) => exam.name === newExamName
      );
      if (newActiveExam) {
        this.$store.commit("setActiveExamName", newExamName);
        this.$store.commit("setActiveStep", 1);
        this.$store.commit("setActiveQuestions", newActiveExam.questions);
        this.$store.commit("setActiveQuestionIndex", 0);
        this.$store.commit("setActiveQuestion", newActiveExam.questions[0]);
        this.checkPaginationShow();

        if (newExamName === "Speaking") {
          this.tempAnswer["Speaking"] = ["Default answer"];
        }
      }
    },
    startNextStep() {
      if (this.activeStep < this.activeExam.steps) {
        if (
          this.activeExam.component === "SpeakingExam" &&
          this.$refs[this.activeExam.component]
        ) {
          this.$refs[this.activeExam.component].submitAnswer();
        }
        this.$store.commit("setActiveStep", this.activeStep + 1);
      } else {
        const isCurrentExamCompleted =
          this.tempAnswer[this.activeExam.name].length ===
          this.activeExam.questions.length;
        if (isCurrentExamCompleted) {
          this.changeActiveExam(this.getNextExamName());
        } else {
          alert(
            "Please complete all questions before proceeding to the next exam."
          );
        }
      }
    },
    getNextExamName() {
      const currentIndex = this.exams.findIndex(
        (exam) => exam.name === this.activeExamName
      );
      const nextIndex = (currentIndex + 1) % this.exams.length;
      if (currentIndex === this.exams.length - 1) {
        alert("Sınavı bitir");
        console.log(this.tempAnswer);
      } else {
        return this.exams[nextIndex].name;
      }
    },
    // checkUnansweredQuestion() {
    //   const currentExamAnswers = this.tempAnswer[this.activeExam.name] || [];
    //   console.log(currentExamAnswers);

    //   if (currentExamAnswers.length === 0) {
    //     alert("Hiçbir soru cevaplanmadı.");
    //     return;
    //   }

    //   const unansweredQuestionIndex = currentExamAnswers.findIndex(
    //     (answer) => answer === ""
    //   );

    //   if (unansweredQuestionIndex !== -1) {
    //     alert(`Soru ${unansweredQuestionIndex + 1} cevaplanmadı.`);
    //   }
    // },
    toggleDarkMode() {
      this.toggleDarkMode(!this.$store.state.dark);
    },
    beforeUnload(e) {
      if (!this.$store.state.examCompleted) {
        const message =
          "Sınavı tamamlamadan ayrılmak istediğinize emin misiniz?";
        e.preventDefault();
        e.returnValue = message;
        return message;
      }
    },
    checkExamCompletion(exam) {
      exam.completed = exam.questions.every(
        (question) => question.answers !== ""
      );
    },
    handleAnswerSubmitted({ examName, answer, questionIndex }) {
      this.tempAnswer[examName][questionIndex] = answer;
    },

    checkPaginationShow() {
      switch (this.activeExam.name) {
        case "Listening":
          this.paginationShow = this.activeStep > 1;
          break;

        case "Reading":
          this.paginationShow = this.activeStep > 1;
          break;
        case "Grammar":
          this.paginationShow = true;
          break;
        case "Vocabulary":
          this.paginationShow = false;
          break;
        case "Writing":
          this.paginationShow = false;
          break;
        case "Speaking":
          this.paginationShow = false;
          break;
        default:
          this.paginationShow = false;
      }
    },
  },
  computed: {
    ...mapState([
      "activeExamName",
      "activeStep",
      "activeQuestions",
      "activeQuestionIndex",
      "dark",
      "activeQuestion",
    ]),

    activeExam() {
      return this.exams.find((exam) => exam.name === this.activeExamName);
    },
    activeSvg() {
      return this.svgList.find((svg) => svg.name === this.activeExam.svgName);
    },
    computedActiveQuestion() {
      return this.activeExam.questions[this.activeQuestionIndex];
    },
    currentExamQuestionCount() {
      const activeExam = this.exams.find((e) => e.name === this.activeExamName);
      return activeExam ? activeExam.questions.length : 0;
    },
    areExamsCompleted() {
      return this.exams.every((exam) => exam.completed);
    },
    activeNote() {
      return this.notes.find((note) => note.name === this.activeExamName);
    },
  },
  mounted() {
    this.changeActiveExam(this.exams[0].name);
  },
  created() {
    window.addEventListener("beforeunload", this.beforeUnload);
  },
  watch: {
    activeStep() {
      this.checkPaginationShow();
    },
    activeExam: {
      handler() {
        this.checkPaginationShow();
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$store.state.examCompleted) {
      const answer = window.confirm(
        "Sınavı tamamlamadan ayrılmak istediğinize emin misiniz?"
      );
      if (answer) {
        this.$store.commit("setExamCompleted", false);
        next();
      } else {
        next(false);
      }
    } else {
      this.$store.commit("setExamCompleted", false);
      next();
    }
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeUnload);
  },
};
</script>
