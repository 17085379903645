<template>
  <div>
    <img
      v-show="this.gifActive === false"
      src="@/assets/video/asistans.png"
      alt=""
      class="w-44 h-44 mt-2 md:mt-0 sm:w-64 sm:h-64 rounded-full "
    />
    <img
      v-show="this.gifActive === true"
      src="@/assets/video/assistants.gif"
      alt=""
      class="w-44 h-44 mt-2 md:mt-0 sm:w-64 sm:h-64 rounded-full "
    />
  </div>
</template>
<script>
export default {
  name: "asistansPage",
  props: ["gifActive"],
};
</script>
