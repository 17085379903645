<template>
  <div>
    <div class="flex space-x-3">
      <button
        v-for="word in words"
        :key="word"
        class="outline outline-1 bg-transparent hover:bg-gray-700 text-white font-bold py-2 px-3 rounded-lg"
        draggable="true"
        @dragstart="dragStart"
      >
        {{ word }}
      </button>
    </div>
    <hr class="my-5 border-t-2 border-gray-50" />
    <div class="">
      <p class="text-xl" v-html="formattedQuestion"></p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "VocabularyExam",
  data() {
    return {
      words: ["Word 1", "Word 2", "Word 3"],
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac nunc euismod, ____ nisl sed, aliquet nunc. Nullam auctor, ____ id ultrices lacinia, nisl nunc tincidunt nunc, vitae lacinia nunc nisl id nunc. Sed ut nunc id nunc lacinia lacinia. Sed id nunc auctor, aliquet nunc id, tincidunt nunc. Sed id nunc auctor, aliquet nunc id, tincidunt nunc. ____ lorem ipsum",
    };
  },
  computed: {
    ...mapState(["activeExamName"]),

    formattedQuestion() {
      let formatted = this.question;
      let index = 0;
      formatted = formatted.replace(/____/g, () => {
        return `<div id="blank-${index++}" class="blank min-w-[50px] inline-block" draggable="true" style="width: auto;">____</div>`;
      });
      return formatted;
    },
  },
  methods: {
    dragStart(event) {
      event.dataTransfer.setData("text/plain", event.target.innerHTML);
    },
    drop(event) {
      event.preventDefault();
      const data = event.dataTransfer.getData("text/plain").trim();
      event.target.innerText = data;
      event.target.style.backgroundColor = "";

      const questionIndex = event.target.id.split("-")[1];
      this.submitAnswer(data, questionIndex);
    },
    dragEnter(event) {
      event.target.style.backgroundColor = "yellow";
    },
    dragLeave(event) {
      event.target.style.backgroundColor = "";
    },
    addEventListeners() {
      this.$nextTick(() => {
        const blanks = document.querySelectorAll(".blank");
        blanks.forEach((blank) => {
          blank.addEventListener("dragover", (event) => {
            event.preventDefault();
          });
          blank.addEventListener("drop", this.drop);
          blank.addEventListener("dragenter", this.dragEnter);
          blank.addEventListener("dragleave", this.dragLeave);
        });
      });
    },
    submitAnswer(answer, questionIndex) {
      this.$emit("answer-submitted", {
        examName: this.activeExamName,
        answer,
        questionIndex,
      });
    },
  },
  mounted() {
    this.addEventListeners();
  },
  updated() {
    this.addEventListeners();
  },
};
</script>

<!-- <template>
  <div>
    <draggable v-model="words" class="flex space-x-3">
      <button
        v-for="word in words"
        :key="word"
        class="outline outline-1 bg-transparent hover:bg-gray-700 text-white font-bold py-2 px-3 rounded-lg"
        @dragstart="dragStart($event, word)"
      >
        {{ word }}
      </button>
    </draggable>
    <hr class="my-5 border-t-2 border-gray-50" />
    <div class="">
      <p class="text-xl" v-html="formattedQuestion"></p>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "VocabularyExam",
  components: {
    draggable,
  },
  data() {
    return {
      words: ["Word 1", "Word 2", "Word 3"],
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac nunc euismod, ____ nisl sed, aliquet nunc. Nullam auctor, ____ id ultrices lacinia, nisl nunc tincidunt nunc, vitae lacinia nunc nisl id nunc. Sed ut nunc id nunc lacinia lacinia. Sed id nunc auctor, aliquet nunc id, tincidunt nunc. Sed id nunc auctor, aliquet nunc id, tincidunt nunc. ____ lorem ipsum",
      draggedWord: null,
    };
  },
  computed: {
    formattedQuestion() {
      let formatted = this.question;
      formatted = formatted.replace(
        /____/g,
        `<div class="blank min-w-[50px] inline-block" draggable="true" style="width: auto;" @drop="drop($event)" @dragover.prevent></div>`
      );
      return formatted;
    },
  },
  methods: {
    dragStart(event, word) {
      this.draggedWord = word;
    },
    drop(event) {
      event.target.innerText = this.draggedWord;
    },
  },
};
</script> -->
