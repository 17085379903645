<template>
  <div class="flex flex-col items-center justify-center space-y-6">
    <h2 class="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl mb-4">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias illum
      laboriosam, quibusdam iste voluptatem natus rem dolores non quis
      voluptatum dolor saepe sint dolore sequi excepturi similique repellendus,
      assumenda nemo.
    </h2>
    <div
      class="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 rounded-xl bg-transparent outline outline-1 mb-4 flex items-center justify-center"
    >
      <img
        src="../../../../../assets/assestments/icons/jam_mic-alt.svg"
        alt="Mic Icon"
        class="h-12 w-12"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SpeakingExam",
  data() {
    return {};
  },
  methods: {
    submitAnswer() {
      const answer = this.convertSpeechToText(); // sesi metne çeviren metod
      this.$emit("answer-submitted", { examName: "Speaking", answer });
    },
  },
};
</script>
