<template>
  <div class="flex justify-center items-center w-full h-full">
    <div class="h-full flex flex-col space-y-4 overflow-y-auto">
      <contentPanel @nextPage="(val) => (nextPage = val)" />
      <startPanel :nextPage="nextPage" />
    </div>
  </div>
</template>
<script>
import contentPanel from "./components/content.vue";
import startPanel from "./components/start-btn.vue";
export default {
  name: "preliminary-information",
  components: {
    contentPanel,
    startPanel,
  },
  data() {
    return {
      nextPage: false,
    };
  },

  created() {
    if (this.isMobile() && this.$store.state.mobileState.inform) {
      this.$swal({
        title: "Devam?",
        text: "Devam Etmek İstermisiniz?",
        icon: "question",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "evet,istiyorum",
      });
    } else {
      this.$store.commit("mobileInform", true);
    }
    if (!this.$store.state.consentStatement.isValid && this.$route.path != "/") {
      this.$router.push("/");
    }
  },
  watch: {
    "$store.state.consentStatement.isValid"(val) {
      if (!val && this.$route.path != "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>
