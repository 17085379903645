<template>
  <div class="flex justify-center items-center text-white">
    <AudioComponent v-if="currentStep == 1" />
    <QuestionsComponent
      v-if="currentStep == 2"
      :question="activeExam.questions[activeQuestionIndex]"
      :question-index="activeQuestionIndex"
      @answer-submitted="handleAnswerSubmitted"
    />
  </div>
</template>

<script>
import AudioComponent from "./components/audioComponent.vue";
import QuestionsComponent from "../../../components/questionsComponent.vue";

export default {
  name: "listeningExam",
  components: {
    AudioComponent,
    QuestionsComponent,
  },
  props: {
    steps: {
      type: Number,
      default: 0,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
    activeExam: {
      type: Object,
      default: () => {},
    },
    activeQuestionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleAnswerSubmitted({ examName, answer, questionIndex }) {
      this.$emit("answer-submitted", { examName, answer, questionIndex });
    },
  },
};
</script>
