<template>
  <div></div>
</template>

<script>
import { Howl } from "howler";
import { meetingInterview } from "@/networking/urlmanager";

export default {
  props: ["value"],
  data() {
    return {
      sound: null,
      load: false,
      playing: false,
    };
  },
  methods: {
    async synthesizeSpeech() {
      if (!this.value) return;
      this.load = true;
      try {
        let url = "";
        if (this.value.url) {
          url = this.value.url;
        } else {
          const response = await this.axios.post(
            meetingInterview.textToSpeech,
            {
              text: this.value,
            }
          );

          const mp3Array = response.data.data.buffer;
          const uint8Array = new Uint8Array(mp3Array.data);
          const blob = new Blob([uint8Array], { type: "audio/mp3" });
          url = URL.createObjectURL(blob);
        }
        if (!this.playing) {
          this.sound = new Howl({
            src: [url],
            format: ["mp3"],
            autoplay: true,
            volume: 0.5,
            onplayerror: () => {
              this.sound.once("unlock", () => {
                this.sound.unload();
                this.sound.load();
                this.sound.play();
              });
            },
            onplay: () => {
              this.playing = true;
              this.$emit("startValue", true);
            },
            onend: () => {
              this.playing = false;
              this.$emit("endValue", true);
            },
          });
          this.sound.play();
        }
      } catch (error) {
        console.error("Sesli çıktı oluşturma başarısız oldu:", error);
        this.load = false;
      }
    },
  },
  watch: {
    value(newText, oldText) {
      if (!newText && newText != oldText) {
        this.synthesizeSpeech();
      }
    },
  },
  async created() {
    // await this.$swal({
    //   title: "Devam?",
    //   text: "Devam Etmek İstermisiniz?",
    //   icon: "question",
    //   confirmButtonColor: "#3085d6",
    //   confirmButtonText: "Devam",
    // });
    if (this.value) this.synthesizeSpeech();
  },
  beforeDestroy() {
    if (this.sound) {
      this.sound.stop();
    }
  },
};
</script>
