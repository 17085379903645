<template lang="">
  <div>
    <p class="">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod,
      nunc id ultrices ullamcorper, nisl nunc tincidunt nunc, id aliquet nunc
      nunc sed nunc. Sed id nunc auctor, aliquet nunc id, aliquam nunc. Sed id
      nunc auctor, aliquet nunc id, aliquam nunc.
    </p>
    <textarea
      v-model="answer"
      class="resize-none bg-gray-400/20 border border-gray-300 rounded-md py-2 px-4 block w-full appearance-none leading-normal mt-5"
      rows="6"
      cols="50"
      placeholder="Metin alanı..."
    ></textarea>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WritingExam",
  data() {
    return {
      answer: "",
      timeout: null,
    };
  },
  computed: {
    ...mapState(["activeExamName"]),
  },
  watch: {
    answer() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.submitAnswer();
      }, 500);
    },
  },
  methods: {
    submitAnswer() {
      this.$emit("answer-submitted", {
        examName: this.activeExamName,
        answer: this.answer,
        questionIndex: 0,
      });
    },
  },
};
</script>
