<template>
  <div>
    <div
      class="flex flex-col items-center bg-gray-50 sm:rounded-3xl w-full my-5"
    >
      <div
        class="flex justify-between card-header p-5 bgcol w-full sm:rounded-t-2xl text-white"
      >
        <h1 class="text-lg sm:text-xl">Ön Görüşme Randevusu </h1>
      </div>
      <div class="w-full px-4 sm:px-10">
        <div class="p-5 text-center text-blue-800 font-bold">
          <h2 class="text-sm sm:text-md">
            {{
              allDatesEnabled
                ? "Öngörüşme için size en uygun tarih ve saat aralığını seçiniz."
                : "Öngörüşme için en uygun tarih ve saat aralığını seçiniz."
            }}
          </h2>
        </div>
        <div class="w-full">
          <calendar
            ref="calendarRef"
            :dates="availableDates"
            :disablePastDates="true"
            :isLoading="isLoading"
            :maxSelectedTimes="3"
            :maxSelectedTimesWarning="'Toplamda en fazla 3 tarih seçebilirsiniz.'"
            @update:selectedDates="(newDates) => (selectedTimes = newDates)"
            @dates-initialized="(dates) => (this.availableDates = dates)"
            :selectedTimes="selectedTimes"
          />
          <div class="w-full border border-gray-400 rounded my-5">
            <ul>
              <li v-if="selectedTimes.length === 0" class="text-center p-1">
                Lütfen Tarih Seçiniz
              </li>

              <li
                v-else
                class="grid grid-cols-3 p-1 border-b border-b-gray-400"
              >
                <strong>Tarih</strong>
                <strong class="col-span-2">Saat</strong>
              </li>

              <li
                v-for="(item, index) in selectedTimes"
                :key="index"
                class="grid grid-cols-3 p-1 border-b border-gray-400 items-center"
              >
                <span>{{ formatDate(item.date) }}</span>
                <div class="flex overflow-x-auto scroll-design col-span-2">
                  <button
                    v-for="(time, timeIndex) in getTimesForSelectedDate(
                      item.date
                    )"
                    :key="timeIndex"
                    class="text-black border px-1 py-1 m-1 rounded-lg shadow-lg whitespace-nowrap"
                    @click="selectTime(item.date, time)"
                    :class="{
                      '!bg-blue-500 text-white': item.times.includes(time),
                      'hover:bg-blue-500/90 hover:text-white':
                        !item.times.includes(time),
                    }"
                  >
                    {{ time }}
                  </button>
                </div>
              </li>
            </ul>
          </div>

          <div
            class="flex flex-col sm:flex-row items-center justify-between space-x-0 sm:space-x-3 my-5 w-full"
          >
            <asyncButton
              text="Gönder"
              buttonClass="w-full text-sm  text-white"
              containerClass="w-full sm:w-2/4 flex items-center justify-center mt-2 sm:mt-0 rounded"
              :disabled="selectedTimes.length === 0"
              :class="{
                'bg-gray-400': selectedTimes.length === 0,
                'bg-blue-500 hover:bg-blue-500/90': selectedTimes.length > 0,
              }"
              :load="confirmLoading"
              @click="
                () => (allDatesEnabled ? handleAction(4) : handleAction(3))
              "
            />
            <asyncButton
              :text="allDatesEnabled ? 'Geri' : 'Diğer Tarihler'"
              buttonClass="w-full text-white bg-red-500 text-sm hover:bg-red-500/90 "
              containerClass="w-full sm:w-2/4 flex items-center justify-center mt-2 sm:mt-0"
              @click="saveReject()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import asyncButton from "@/components/button.vue";
import { technicalInterviewMicrosoft } from "@/networking/urlmanager";
import calendar from "./calendar.vue";

export default {
  name: "select-date-modal",
  components: { asyncButton, calendar },
  props: ["detail"],
  data() {
    moment.locale("tr");
    return {
      selectedTimes: [],
      availableDates: [],
      isLoading: false,
      confirmLoading: false,
      allDatesEnabled: false,
      timeSlots: this.generateTimeSlots("09:00", "18:00"),
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("LL");
    },

    // Bu metot, belirli bir başlangıç ve bitiş zamanı arasında saatlik zaman dilimleri oluşturur.
    generateTimeSlots(startTime, endTime) {
      const start = moment(startTime, "HH:mm");
      const end = moment(endTime, "HH:mm");
      const timeSlots = [];

      while (start <= end) {
        const slotStart = start.format("HH:mm");
        start.add(1, "hour");
        const slotEnd = start.format("HH:mm");
        timeSlots.push(`${slotStart} / ${slotEnd}`);
      }

      return timeSlots;
    },

    // Bu metot, seçilen zamanı yönetir ve belirli bir tarihte seçilen zamanları takip eder.
    selectTime(date, time) {
      const maxHours = this.allDatesEnabled ? 3 : 1;

      let selectedDate = this.selectedTimes.find((item) => item.date === date);

      if (!selectedDate) {
        selectedDate = { date: date, times: [] };
        this.selectedTimes.push(selectedDate);
      }

      if (this.isTimeSelected(selectedDate, time)) {
        this.removeTime(selectedDate, time);
      } else {
        const totalSelectedHours = this.selectedTimes.reduce(
          (acc, date) => acc + date.times.length,
          0
        );

        if (totalSelectedHours < maxHours) {
          selectedDate.times.push(time);
        } else {
          this.warningMesage(
            `Toplam seçilen saat ${maxHours} saatten fazla olamaz.`
          );
          return;
        }
      }

      if (this.totalSelectedHours() === maxHours) {
        this.selectedTimes = this.selectedTimes.filter(
          (date) => date.times.length > 0
        );
      }
    },

    // Bu metot, toplam seçilen saat sayısını döndürür.
    totalSelectedHours() {
      return this.selectedTimes.reduce(
        (acc, date) => acc + date.times.length,
        0
      );
    },

    // Bu metot, belirli bir tarihte belirli bir zamanın seçilip seçilmediğini kontrol eder.
    isTimeSelected(selectedDate, time) {
      return selectedDate.times.includes(time);
    },

    // Bu metot, belirli bir tarihe belirli bir zaman ekler.
    addTime(selectedDate, time) {
      const totalSelectedHours = this.selectedTimes.reduce(
        (acc, date) => acc + date.times.length,
        0
      );

      if (totalSelectedHours < 3) {
        selectedDate.times.push(time);

        const newTotalSelectedHours = this.selectedTimes.reduce(
          (acc, date) => acc + date.times.length,
          0
        );

        if (newTotalSelectedHours === 3) {
          this.selectedTimes = this.selectedTimes.filter(
            (date) => date.date === selectedDate.date
          );
        }
      } else {
        this.warningMesage("Toplam seçilen saat 3 saatten fazla olamaz.");
      }
    },

    // Bu metot, belirli bir tarihten belirli bir zamanı kaldırır.
    removeTime(selectedDate, time) {
      const index = selectedDate.times.findIndex(
        (selectedTime) => selectedTime === time
      );
      if (index > -1) {
        selectedDate.times.splice(index, 1);
      }
    },

    // Bu metot, tüm tarihleri etkinleştirir veya devre dışı bırakır.
     async saveReject() {
      this.allDatesEnabled = !this.allDatesEnabled;
      this.selectedTimes = [];
      if (this.allDatesEnabled) {
        this.$refs.calendarRef.initializeAvailableDates();
      } else {
        this.availableDates = this.$store.state.interviewDates.map((dateStr) => {
          const dateTime = moment.utc(this.preprocessDate(dateStr));  // Use moment.utc to avoid local time zone conversion
          return {
            date: dateTime.format("YYYY-MM-DD"),
            times: [dateTime.format("HH:mm")],
          };
        });
      }
    },

    // Bu metot, belirli bir durum kimliği ile bir eylemi yönetir.
    handleAction(stateId) {
      const requestBody = { stateId: stateId };
      this.confirmLoading = true;

      requestBody.selectedDate = this.formatselectedTimes();

      const url = stateId === 4 ? technicalInterviewMicrosoft.suggestMeetDate : technicalInterviewMicrosoft.createEvent;

      this.sendRequest(url, requestBody);
    },

    // Bu metot, seçilen zamanları biçimlendirir.
    formatselectedTimes() {
      return this.selectedTimes.flatMap(({ date, times }) =>
        times.map((time) =>
          moment
            .utc(`${date} ${time.split(" / ")[0]}`, "YYYY-MM-DD HH:mm")
            .format("YYYY-MM-DDTHH:mm:ss[Z]")
        )
      );
    },

    // Bu metot, belirli bir URL ve istek gövdesi ile bir istek gönderir.
    sendRequest(url, requestBody) {
      this.axios
        .post(url, requestBody, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(() => {
          setTimeout(() => {
            this.$emit("isOk", true);
            this.confirmLoading = false;
          }, 500);
        })
        .catch((error) => {
          this.confirmLoading = false;
          this.authController(error);
          console.error(error);
        });
    },

    // Bu metot, belirli bir tarih için mevcut zamanları alır ve döndürür.
    getTimesForSelectedDate(selectedDate) {
      const times = this.availableDates
        .filter((dateObj) => dateObj.date === selectedDate)
        .map((dateObj) => dateObj.times)
        .flat();

      if (times.length === 0) {
        return [""];
      }

      const earliestTime = Math.min(
        ...times.map((time) => moment(time, "HH:mm").valueOf())
      );
      let latestTime = Math.max(
        ...times.map((time) => moment(time, "HH:mm").valueOf())
      );

      if (earliestTime === latestTime) {
        latestTime = moment(earliestTime).add(1, "hours").valueOf();
      }

      const timeSlots = this.generateTimeSlots(
        moment(earliestTime).format("HH:mm"),
        moment(latestTime).format("HH:mm")
      );

      let availableTimes = times
        .map((time) => {
          const nextHour = moment(time, "HH:mm")
            .add(1, "hours")
            .format("HH:mm");
          return `${time} / ${nextHour}`;
        })
        .filter((time) => timeSlots.includes(time));

      return availableTimes.length > 0 ? availableTimes : [""];
    },

    // Bu metot, bir tarih dizesini işler ve döndürür.
    preprocessDate(dateStr) {
      if (!dateStr) {
        return;
      }
      const [date, time] = dateStr.split(/(?<=^\d{4}-\d{2}-\d{2})/);
      return `${date}${time}`;
    }
  },

  mounted() {
    this.availableDates = this.$store.state.interviewDates.map((dateStr) => {
      const dateTime = moment.utc(this.preprocessDate(dateStr));  // Use moment.utc to avoid local time zone conversion
      return {
        date: dateTime.format("YYYY-MM-DD"),
        times: [dateTime.format("HH:mm")],
      };
    });
  }
};
</script>
