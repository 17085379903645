<template>
  <div class="flex flex-col justify-center items-center text-white">
    <p
      class="w-full text-center text-lg sm:w-full md:w-3/4 lg:w-2/3 xl:w-2/3 mx-auto mt-4 sm:mt-6 md:mt-8 text-gray-400"
    >
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita
      perferendis quos explicabo consectetur asperiores adipisci, tempora rerum
      odit, a placeat modi culpa! Quibusdam impedit earum nesciunt eius
      asperiores odio reiciendis.
    </p>
    <div class="flex justify-center mt-4 sm:mt-6 md:mt-8 space-x-4">
      <button
        class="outline outline-1 px-3 sm:px-3 md:px-5 py-2 sm:py-2 md:py-3 rounded-lg bg-blue-950/60 hover:bg-blue-900/60"
      >
        <img
          src="../../../../../../assets//assestments//icons/listening/repeat.svg"
          alt="repeat icon"
          class="w-6 sm:w-6 md:w-8 h-4 sm:h-4 md:h-6"
        />
      </button>
      <button
        class="outline outline-1 px-3 sm:px-3 md:px-5 py-2 sm:py-2 md:py-3 rounded-lg bg-blue-950/60 hover:bg-blue-900/60"
      >
        <img
          src="../../../../../../assets//assestments//icons/listening/pause.svg"
          alt="stop icon"
          class="w-6 sm:w-6 md:w-8 h-4 sm:h-4 md:h-6"
        />
      </button>
      <button
        class="outline outline-1 px-3 sm:px-3 md:px-5 py-2 sm:py-2 md:py-3 rounded-lg bg-blue-950/60 hover:bg-blue-900/60"
      >
        <img
          src="../../../../../../assets//assestments//icons/listening/play.svg"
          alt="play icon"
          class="w-6 sm:w-6 md:w-8 h-4 sm:h-4 md:h-6"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AudioComponent",
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
