<template>
  <div class="flex items-center justify-center">
    <h2 class="text-xs sm:text-sm md:text-lg w-5/6">
      {{ text }}
    </h2>
  </div>
</template>

<script>
export default {
  name: "QuestionsComponent",
  data() {
    return {
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo et veritatis, beatae assumenda labore blanditiis impedit dicta voluptatum iusto, deserunt maxime delectus ad libero quisquam sint eaque molestiae, quos aliquid?",
    };
  },
  // props: {
  //   text: {
  //     type: String,
  //     default: "",
  //   },
  // },
};
</script>
