var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col items-center justify-between h-full"},[_c('div',[_c('h2',{staticClass:"text-xl font-bold mb-4 text-white"},[_vm._v(" "+_vm._s(_vm.question.question)+" ")]),_c('div',{staticClass:"grid grid-cols-2 gap-3 sm:gap-4 md:gap-5 md:flex md:space-x-5 items-center md:space-y-0"},_vm._l((_vm.question.answers),function(answer,answerIndex){return _c('answerButton',{key:answerIndex,attrs:{"text":answer,"button-class":[
          'outline',
          'outline-1',
          'font-bold',
          'py-2',
          'px-8',
          'rounded',
          'bg-transparent',
          answer !== _vm.selectedAnswers[_vm.questionIndex]
            ? ['bg-blue-800/20', 'hover:bg-blue-700', 'text-white']
            : [],
          answer === _vm.selectedAnswers[_vm.questionIndex]
            ? ['bg-blue-700', 'text-white']
            : [],
        ]},on:{"save":function($event){return _vm.submitAnswer(answer)}}})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }