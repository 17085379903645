<template>
  <div class="w-full">
    <div v-if="!isLoading">
      <div class="flex justify-between space-x-3 mb-5">
        <!-- Ay seçimi -->
        <button type="button" @click="goToNextOrPreviousMonth(false)">
          <i class="fas fa-chevron-left"></i>
        </button>

        <div class="flex items-center">
          <select v-model="selectedMonth" class="bg-gray-50">
            <option
              v-for="(month, index) in months"
              :key="index"
              :value="index"
            >
              {{ month }}
            </option>
          </select>

          <select v-model="selectedYear" class="bg-gray-50">
            <option v-for="year in years" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
        </div>

        <!-- Yıl seçimi -->
        <button type="button" @click="goToNextOrPreviousMonth(true)">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
      <div>
        <!-- Haftanın günleri -->
        <div class="grid grid-cols-7 text-center">
          <div
            class="py-2 font-bold"
            v-for="day in ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz']"
            :key="day"
          >
            {{ day }}
          </div>
        </div>

        <!-- Ayın günleri -->
        <div class="grid grid-cols-2 sm:grid-cols-7 gap-2 mt-2">
          <button
            class="py-2 rounded-lg shadow-md"
            v-for="(day, index) in daysInMonth"
            :key="`${day.day}-${index}`"
            type="button"
            :disabled="isDisabled(day)"
            @click="selectDay(day)"
            :class="{
              ['hover:bg-' +
              colorPalette.hover +
              ' hover:text-white ' +
              ' text-blue-500 font-semibold']:
                !isDisabled(day) && day.isSelectedMonthDay,
              ['bg-' + colorPalette.selected + ' text-white']: isSelected(day),
              'opacity-30': isDisabled(day),
              'text-red-500': !day.isSelectedMonthDay && !isDisabled(day),
            }"
          >
            {{ day.day }}
          </button>
        </div>
      </div>
    </div>

    <div
      v-else-if="isLoading"
      class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-black backdrop-blur"
    >
      <div
        class="animate-spin border-t-4 border-b-4 border-blue-500 rounded-full h-12 w-12"
      ></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "calendarComponent",
  props: {
    colorPalette: {
      type: Object,
      default: () => ({
        primary: "blue-500",
        secondary: "red-500",
        disabled: "gray-500",
        selected: "blue-500",
        hover: "blue-500/90",
      }),
    },
    dates: {
      type: Array,
      default: () => [],
    },
    selectedTimes: {
      type: Array,
      default: () => [],
    },
    disablePastDates: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    maxSelectedTimes: {
      type: Number,
      default: 3,
    },
    maxSelectedTimesWarning: {
      type: String,
      default: "Toplamda en fazla 3 saat seçebilirsiniz.",
    },
    startHour: {
      type: String,
      default: "09:00",
    },
    endHour: {
      type: String,
      default: "18:00",
    },
  },
  data() {
    moment.locale("tr");
    return {
      selectedDates: [], // Seçilen tarihleri tutan dizi
      selectedMonth: moment().month(), // Seçilen ayın indeksi
      selectedYear: moment().year(), // Seçilen yıl
      months: moment.months(), // Tüm ayların listesi
      years: Array.from({ length: 10 }, (_, i) => moment().year() + i), // 10 yıllık bir liste
    };
  },
  computed: {
    daysInMonth() {
      const daysInThisMonth = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
      }).daysInMonth(); // Seçilen aydaki gün sayısı
      const firstDayOfMonth = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: 1,
      }).day(); // Ayın ilk gününün haftanın hangi günü olduğu
      const daysInPreviousMonth = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
      })
        .subtract(1, "month")
        .daysInMonth(); // Önceki aydaki gün sayısı
      const days = [];
      for (let i = 0; i < firstDayOfMonth - 1; i++) {
        days.push({
          day: daysInPreviousMonth - i,
          isSelectedMonthDay: false,
          id: `${this.selectedYear}-${this.selectedMonth - 1}-${
            daysInPreviousMonth - i
          }`,
        });
      }
      days.reverse(); // Önceki ayın günlerini ekler
      for (let i = 1; i <= daysInThisMonth; i++) {
        days.push({
          day: i,
          isSelectedMonthDay: true,
          id: `${this.selectedYear}-${this.selectedMonth}-${i}`,
        }); // Seçilen ayın günlerini ekler
      }
      return days;
    },
    // availableDates: this.dates, // Kullanılabilir tarihlerin listesi

    availableDates() {
      if (this.dates.length === 0) {
        return this.initializeAvailableDates();
      } else {
        return this.dates;
      }
    },
  },
  methods: {
    goToNextOrPreviousMonth(next = false) {
      let currentDate = moment([this.selectedYear, this.selectedMonth]);

      if (next) {
        currentDate = currentDate.add(1, "months"); // Bir sonraki ayı seçer
      } else {
        currentDate = currentDate.subtract(1, "months"); // Bir önceki ayı seçer
      }

      this.selectedMonth = currentDate.month();
      this.selectedYear = currentDate.year();
    },
    isDisabled(day) {
      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      });
      const formattedDate = date.format("YYYY-MM-DD");
      const isAvailable = this.availableDates.some(
        (availableDate) => availableDate.date === formattedDate
      ); // Seçilen tarih kullanılabilir mi?

      const isSelected = this.selectedDates.some(
        (selectedDate) => selectedDate.date === formattedDate
      ); // Seçilen tarih zaten seçilmiş mi?

      const totalSelectedTimes = this.selectedDates.reduce(
        (total, date) => total + date.times.length,
        0
      ); // Toplam seçilen saat sayısı

      const totalSelectedDates = this.selectedDates.length; // Toplam seçilen tarih sayısı

      return (
        !isAvailable ||
        (totalSelectedTimes >= this.maxSelectedTimes && !isSelected) ||
        (totalSelectedDates >= this.maxSelectedTimes && !isSelected)
      ); // Tarih seçilemez durumda mı?
    },
    isDateSelected(day) {
      const formattedDate = moment([
        this.selectedYear,
        this.selectedMonth,
        day.day,
      ]).format("YYYY-MM-DD");
      return this.selectedDates.some(
        (selectedDate) => selectedDate.date === formattedDate
      ); // Tarih seçilmiş mi?
    },
    addDate(day) {
      const formattedDate = moment([
        this.selectedYear,
        this.selectedMonth,
        day.day,
      ]).format("YYYY-MM-DD");
      const availableDate = this.availableDates.find(
        (availableDate) => availableDate.date === formattedDate
      );
      if (availableDate) {
        this.selectedDates.push({
          date: availableDate.date,
          times: [],
        }); // Tarihi seçilen tarihler listesine ekler
        this.$emit("update:selectedDates", this.selectedDates);
      }
    },
    removeDate(day) {
      const formattedDate = moment([
        this.selectedYear,
        this.selectedMonth,
        day.day,
      ]).format("YYYY-MM-DD");
      const index = this.selectedDates.findIndex(
        (selectedDate) => selectedDate.date === formattedDate
      );
      if (index > -1) {
        this.selectedDates.splice(index, 1); // Tarihi seçilen tarihler listesinden çıkarır
      }
    },
    selectDay(day) {
      if (!this.isDisabled(day)) {
        const isSelected = this.isDateSelected(day);
        if (isSelected) {
          this.removeDate(day); // Tarihi seçilen tarihler listesinden çıkarır
        } else {
          // Seçilen günün ayını ve yılını günceller
          if (!day.isSelectedMonthDay) {
            if (day.day > 7) {
              this.goToNextOrPreviousMonth(false); // Önceki aya gider
            } else {
              this.goToNextOrPreviousMonth(true); // Sonraki aya gider
            }
          }
          this.addDateIfPossible(day); // Tarihi seçilen tarihler listesine ekler (maksimum seçim sayısını kontrol eder)
        }
      }
    },
    createFormattedDate(day) {
      const date = moment({
        year: this.selectedYear,
        month: this.selectedMonth,
        day: day.day,
      });
      return date.format("YYYY-MM-DD"); // Tarihi formatlar
    },
    isSelected(day) {
      if (!day.isSelectedMonthDay) {
        return false;
      }
      const formattedDate = this.createFormattedDate(day);
      return this.selectedDates.some(
        (selectedDate) => selectedDate.date === formattedDate
      ); // Tarih seçilmiş mi?
    },
    addDateIfPossible(day) {
      const totalSelectedTimes = this.getTotalSelectedTimes();
      if (totalSelectedTimes < 3) {
        this.addDate(day); // Tarihi seçilen tarihler listesine ekler
      } else {
        this.warningMesage(this.maxSelectedTimesWarning, 2000); // Uyarı mesajı gösterir
      }
    },
    getTotalSelectedTimes() {
      return this.selectedDates.reduce(
        (total, date) => total + date.times.length,
        0
      ); // Toplam seçilen saat sayısını döner
    },
    goToAvailableMonth(afterCurrent = false) {
      if (this.availableDates.length > 0) {
        if (afterCurrent) {
          const currentDate = new Date(
            this.selectedYear,
            this.selectedMonth + 1,
            1
          );
          for (let i = 0; i < this.availableDates.length; i++) {
            const availableDate = moment(this.availableDates[i].date);
            if (availableDate >= currentDate) {
              this.selectedMonth = availableDate.month();
              this.selectedYear = availableDate.year();
              break;
            }
          }
        } else {
          const firstAvailableDate = moment(this.availableDates[0].date);
          this.selectedMonth = firstAvailableDate.month();
          this.selectedYear = firstAvailableDate.year();
        }
      }
    },
    initializeAvailableDates() {
      let dates = [];

      const startOfYearDate = moment({
        year: this.selectedYear,
      }).startOf("year");
      const endOfYearDate = moment({
        year: this.selectedYear,
      }).endOf("year");

      for (
        let m = moment(startOfYearDate);
        m.isBefore(endOfYearDate);
        m.add(1, "days")
      ) {
        if (m.isSameOrAfter(moment(), "day")) {
          const day = {
            date: m.format("YYYY-MM-DD"),
            times: [],
          };

          const start = moment(this.startHour, "HH:mm");
          const end = moment(this.endHour, "HH:mm");
          while (start.isSameOrBefore(end)) {
            day.times.push(start.format("HH:mm"));
            start.add(1, "hour");
          }
          dates.push(day);
        }
      }

      this.$emit("dates-initialized", dates);
      return dates;
    },
  },
  mounted() {
    this.selectedDates = [];
    if (this.availableDates.length > 0) {
      this.goToAvailableMonth();
    } else {
      this.selectedMonth = moment().month();
      this.selectedYear = moment().year();
    }
  },
  watch: {
    availableDates(newVal) {
      if (newVal.length > 0) {
        this.goToAvailableMonth();
      } else {
        this.selectedMonth = moment().month();
        this.selectedYear = moment().year();
      }
    },
    selectedTimes: {
      handler(newDates) {
        this.selectedDates = newDates;
      },
      deep: true,
    },
  },
};
</script>
