<template>
  <div class="flex justify-center items-center text-white">
    <TextComponent v-if="currentStep == 1" />
    <div class="flex flex-col gap-5">
      <TextComponent v-if="currentStep == 2" />
      <QuestionsComponent
        v-if="currentStep == 2"
        :question="activeExam.questions[activeQuestionIndex]"
        :question-index="activeQuestionIndex"
        @answer-submitted="handleAnswerSubmitted"
      />
    </div>
  </div>
</template>
<script>
import TextComponent from "./components/textComponent";
import QuestionsComponent from "../../../components/questionsComponent.vue";

export default {
  name: "ReadingExam",
  components: {
    TextComponent,
    QuestionsComponent,
  },
  props: {
    steps: {
      type: Number,
      default: 0,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
    activeExam: {
      type: Object,
      default: () => {},
    },
    activeQuestionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleAnswerSubmitted({ examName, answer, questionIndex }) {
      this.$emit("answer-submitted", { examName, answer, questionIndex });
    },
  },
};
</script>
