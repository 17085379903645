<template>
  <div class="w-full flex items-center justify-center">
    <verifyConsentStatement
      ref="refConsentStatement"
      v-if="isConsentStatement"
      @change="isConsentStatement = !isConsentStatement"
      :load="load"
    />

    <viewFile ref="viewFile" :selectFile="selectFile" />
    <div v-if="!isConsentStatement" class="w-full overflow-y-auto h-full">
      <div class="w-9/12 p-2 mx-auto max-lg:w-11/12 max-sm:w-full">
        <h2 class="text-center text-2xl font-semibold text-white pb-3 pt-4">
          Özlük Bilgileri
        </h2>

        <form @submit.prevent="add">
          <div v-for="(item, index) in list" :key="index">
            <card
              :item="item"
              :load="load"
              :fileNames="fileNames"
              @fileUpload="(r) => ((item.file = r), (item.fileType = 1))"
              @selectFile="() => ((selectFile = item), $refs.viewFile.show())"
              @fileName="addFileName"
            />
          </div>

          <div class="flex justify-end">
            <buttonItem
              :disabled="disabledButton"
              :load="saveLoad"
              :text="isSave ? 'Gönder' : 'Güncelle'"
              :buttonClass="[
                'text-white !mt-5 px-5',
                disabledButton ? 'bg-blue-500/50' : 'w-full bg-blue-500 ',
              ]"
            />
          </div>
        </form>
      </div>
      <div class="mt-5">
        <div class="w-10/12 mx-auto h-[1px]" :style="footerStyle"></div>
        <h1 class="text-ceter text-white text-center py-5 text-sm">
          <b>NOT:</b> Gerekli evrakları eksiksiz yükledikten sonra, 7 gün içinde
          ekleme veya düzenleme yapabilirsiniz.
        </h1>
      </div>
    </div>
  </div>
</template>
<script>
import card from "./components/card.vue";
import viewFile from "./components/viewFile.vue";
import verifyConsentStatement from "./components/verifyConsentStatement.vue";
import axios from "axios";
import { candidate } from "@/networking/urlmanager";
import buttonItem from "@/components/button.vue";

export default {
  name: "personel-information",
  components: {
    card,
    viewFile,
    buttonItem,
    verifyConsentStatement,
  },
  data() {
    return {
      selectFile: "",
      load: false,
      saveLoad: false,
      isConsentStatement: false,
      refreshLoad: false,
      footerStyle:
        "background: rgb(7, 18, 56); background: linear-gradient(90deg, rgba(7, 18, 56, 1) 0%, rgba(224, 224, 224, 1) 50%, rgba(7, 18, 56, 1) 100%);",
      list: [
        {
          id: 1,
          referance: "passportPhoto",
          title: "Vesikalık Resim",
          description:
            "Yüzünüzü net bir şekilde gösteren, güncel bir vesikalık fotoğraf ekleyiniz.",
          accept: ".pdf,.jpg,.jpeg,.png",
          file: "",
          fileType: 1,
        },
        {
          id: 2,
          referance: "healthReport",
          title: "Sağlık Raporu",
          description:
            "Son altı ay içinde alınmış güncel bir sağlık raporu ekleyiniz.",
          accept: ".pdf",
          file: "",
          fileType: 1,
        },
        {
          id: 3,
          referance: "diploma",
          title: "Diploma",
          description:
            "En son tamamladığınız eğitimi gösteren diploma belgesini ekleyiniz.",
          accept: ".pdf",
          file: "",
          fileType: 1,
        },
        {
          id: 4,
          referance: "criminalRecordPaper",
          title: "Adli Sicil Kağıdı",
          description:
            "Son altı ay içinde alınmış adli sicil kaydınızı ekleyiniz.",
          accept: ".pdf",
          file: "",
          fileType: 1,
        },
        {
          id: 5,
          referance: "certificateResidence",
          title: "İkametgah Belgesi",
          description: "Güncel ikametgah belgenizi ekleyiniz.",
          accept: ".pdf",
          file: "",
          fileType: 1,
        },
      ],
      fileNames: [],
    };
  },
  computed: {
    disabledButton() {
      let data = this.list.filter((r) => {
        return r.fileType === 1 && r.file !== "";
      });
      return data.length > 0 ? false : true;
    },
    isSave() {
      let data = this.list.filter((r) => {
        return r.fileType === 2 && r.file != "";
      });
      return data.length > 0 ? false : true;
    },
  },
  methods: {
    async verify() {
      try {
        this.load = true;
        const token = await this.$route.params.token;
        const result = await axios.post(
          candidate.verify,
          {},
          {
            headers: {
              Authorization: "Bareer " + token,
            },
          }
        );

        let data = result.data.data;

        if (data.isConsentStatement == 2) {
          this.$refs.refConsentStatement.chanceIsValid();
          this.isConsentStatement = false;
        }
        this.$store.commit("interviewQuestions", {
          token: this.$route.params.token,
          consentStatement: data.consentStatementFile,
        });
        await this.getAll();
        this.load = false;
      } catch (error) {
        console.log(error);
        this.$router.push("/end");
      }
    },
    async add() {
      this.saveLoad = true;
      const formData = new FormData();

      this.list.forEach((item) => {
        formData.append(item.referance, item.file);
      });
      try {
        const response = await this.axios.post(candidate.add, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        await this.getAll();
        await this.successMessage(response.data.message);
        this.saveLoad = false;
      } catch (error) {
        this.authController(error);
        this.saveLoad = false;
      }
    },
    addFileName(fileName) {
      this.fileNames.push(fileName);
    },
    async getAll() {
      try {
        this.load = true;
        let response = await this.axios.get(candidate.getFileList);
        let {
          passportPhoto,
          healthReport,
          diploma,
          criminalRecordPaper,
          certificateResidence,
        } = response.data.data;
        this.list.forEach((item, i) => {
          if (item.id == 1 && passportPhoto) {
            this.list[i].file = passportPhoto;
            this.list[i].fileType = 2;
          } else if (item.id == 2 && healthReport) {
            this.list[i].file = healthReport;
            this.list[i].fileType = 2;
          } else if (item.id == 3 && diploma) {
            this.list[i].file = diploma;
            this.list[i].fileType = 2;
          } else if (item.id == 4 && criminalRecordPaper) {
            this.list[i].file = criminalRecordPaper;
            this.list[i].fileType = 2;
          } else if (item.id == 5 && certificateResidence) {
            this.list[i].file = certificateResidence;
            this.list[i].fileType = 2;
          }
        });
        this.load = false;
      } catch (err) {
        this.load = false;
        console.log(err);
        this.authController(err);
      }
    },
  },
  created() {
    if (this.$store.state.isPreliminaryInterview == false) {
      this.verify();
    } else {
      this.getAll();
    }
    if (!this.$store.state.consentStatement.isValid && this.$route.path != "/") {
      this.isConsentStatement = true;
    }
  },
};
</script>
