import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

// import meeting from '../views/meeting/index.vue'

//error Page
import interviewNotFound from "../views/errorPage/interviewError.vue";

import endOfProcess from "../views/endOfProcess/index.vue";

//import notFound from "../views/errorPage/notFound.vue";

//interview
import firstPage from "../views/interview/first-step/index.vue";
import secondPage from "../views/interview/second-step-start/index.vue";
import thirdPage from "../views/interview/third-step-interview/index.vue";
import fourPage from "../views/interview/four-step-assistan/index.vue";
import fivePage from "../views/interview/five-step-go-list/index";

import thanksSpeech from "../views/interview/thanksSpeech.vue";
import answerList from "../views/interview/answerList.vue";

import interviewDateFirst from "../views/interviewDate/index.vue";
import interviewDatePage from "../views/interviewDate/datePage/index.vue";

//pre interview
//import preInterviewPage from "../views/preInterview/index.vue";

// assessment
import asseestmentStart from "../views/assesstment/beginA/index.vue";
import examLayout from "../views/assesstment/examLayout/index.vue";
import personalInformation from "../views/personalInformation/index.vue";
import resumeStorageTime from "../views/resumeStorageTime/index.vue";
import interviewSuccess from "../views/interviewDate/datePage/components/success.vue";

Vue.use(VueRouter);

// const modules = {
//   preInterview: 1,
//   assessment: 2,
//   meeting: 3,
//   personalInformation: 4,
//   tecnicaleIntervirew: 5,
// };

const routes = [
  {
    path: "/",
    name: "firstPage",
    component: firstPage,
  },
  // {
  //   path: "/interview/:token",
  //   name: "interview",
  //   component: preInterviewPage,
  // },
  {
    path: "/interview/inform",
    name: "interviewInformation",
    component: secondPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/pre/interview",
    name: "questionAndAnswer",
    component: thirdPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/candidate/question",
    name: "candidateQuestion",
    component: fourPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/complate/answer/list",
    name: "complateAnswerList",
    component: fivePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/answer/list",
    name: "answerList",
    component: answerList,
    meta: { requiresAuth: true },
  },
  {
    path: "/interview/thanks/speech",
    name: "thanksSpeech",
    component: thanksSpeech,
    meta: { requiresAuth: true },
  },
  {
    path: "/interview/setup/pick-date",
    name: "PickInterviewDate",
    component: interviewDatePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/interview/setup/:token",
    name: "SetupInterview",
    component: interviewDateFirst,
  },

  // assessment
  {
    path: "/assessment",
    name: "assessment",
    component: asseestmentStart,
  },
  {
    path: "/assessment/exam",
    name: "exam",
    component: examLayout,
  },

  {
    path: "/personal/information/:token",
    name: "personalInformation",
    component: personalInformation,
  },
  {
    path: "/resume/storage/time/:token/:isVerify",
    name: "resumeStorageTime",
    component: resumeStorageTime,
  },
  {
    path: "/interview/success",
    name: "interviewSuccess",
    component: interviewSuccess,
  },

  // error pages
  // {
  //   path: "*",
  //   name: "notFound",
  //   component: notFound,
  // },
  {
    path: "/interview/not/found",
    name: "interviewNotFound",
    component: interviewNotFound,
  },
  {
    path: "/end",
    name: "end",
    component: endOfProcess,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.state.isPreliminaryInterview
  ) {
    next("/end");
  } else {
    next();
  }
});

export default router;
