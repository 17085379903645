<template>
  <div
    class="border-b border-gray-500 flex p-2 gap-5 items-center py-4 max-md:flex-wrap"
  >
    <div class="w-6/12 max-md:w-full">
      <h4 class="text-white font-semibold max-xl:text-xl 2xl:text-2xl">
        {{ item.title }}
      </h4>
      <p class="text-gray-200 text-xs font-light">
        {{ item.description }}
      </p>
    </div>
    <div class="w-6/12 max-md:w-full">
      <uploadFile
        @fileUpload="(r) => $emit('fileUpload', r)"
        @selectFile="(r) => $emit('selectFile', r)"
        @fileName="(r) => $emit('fileName', r)"
        :referance="item.referance"
        :fileNames="fileNames"
        :accept="item.accept"
        :item="item"
        :load="load"
      />
    </div>
  </div>
</template>
<script>
import uploadFile from "./uploadFile.vue";
export default {
  name: "personel-informations-card",
  props: ["item", "load", "fileNames"],
  components: {
    uploadFile,
  },
};
</script>
