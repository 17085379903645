<template>
  <div class="main">
    <div @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <input
        type="file"
        name="file"
        :id="item.referance"
        class="hidden"
        @change="onChange"
        :ref="item.referance"
        :accept="item.accept || '.pdf,.jpg,.jpeg,.png'"
      />

      <label
        :for="item.referance"
        class="w-full flex cursor-pointer justify-center font-light text-sm"
      >
        <div
          class="w-9/12 p-4 h-[75px] max-sm:h-20 flex items-center text-gray-400 text-xs border-2 border-dashed border-gray-500 border-r-0 rounded-l-xl"
        >
          <div v-if="fileName" class="file-name">{{ fileName }}</div>
          <!-- Display the file name if a file is selected -->
          <div v-else>
            <div v-if="isDragging" class="text-center w-full">
              Dosyayı buraya bırakın.
            </div>
            <p v-else class="text-center">
              Dosyanızı yüklemek için bu alana
              <span class="text-white"
                >sürükle bırak yapabilir veya alana tıklayarak</span
              >
              klasörünüzden seçebilirsiniz. ({{ item.accept }})
            </p>
          </div>
        </div>

        <div
          class="w-3/12 bg-white rounded-r-xl flex justify-center p-3 items-center"
        >
          <div v-if="load">
            <div
              class="w-7 h-7 rounded-full animate-spin border-4 border-solid border-[#071238] border-t-transparent shadow-md"
            ></div>
          </div>
          <img
            v-else-if="files.length == 0 && !item.file"
            src="@/assets/personelInformation/file.svg"
            class="h-full bg-contain"
            alt=""
          />

          <button
            v-else
            type="button"
            @click="selectFile"
            class="text-center underline font-semibold textcol"
          >
            Görüntüle
          </button>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "load", "fileNames"],
  data() {
    return {
      isDragging: false,
      files: [],
      fileName: "",
    };
  },
  methods: {
    onChange() {
      const selectedFile = this.$refs[this.item.referance].files[0];
      if (selectedFile) {
        const dataExtensions = this.item.accept
          .split(",")
          .map((extension) => extension.trim().toLowerCase());
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

        if (!dataExtensions.includes("." + fileExtension)) {
          this.warningMesage(
            "Lütfen dosyanızı yüklerken belirtilen formatta olduğuna dikkat edin"
          );
          return;
        }

        if (this.fileNames.includes(selectedFile.name)) {
          this.warningMesage("Aynı isimde birden fazla dosya yükleyemezsiniz.");
          return;
        }

        this.files = selectedFile;
        this.fileName = selectedFile.name;
        this.$emit("fileUpload", this.files);
        this.$emit("fileName", this.fileName);
      }
    },
    selectFile() {
      this.$emit("selectFile", true);
    },
    // selectFile() {
    //   console.log(this.files);
    //   if (Array.isArray(this.files)) {
    //     if (this.files.length > 0) {
    //       const fileExtension = this.files.name.split(".").pop().toLowerCase();
    //       const fileUrl = this.generateURL(this.files);
    //       const type = this.getFileType(fileExtension);
    //       this.$emit("selectFile", {
    //         type: type,
    //         fileUrl: fileUrl,
    //         file: this.files,
    //       });
    //     } else {
    //     this.$emit("selectFile", { type: 1 });
    //   }
    //   } else {
    //     this.$emit("selectFile", { type: 1 });
    //   }
    // },

    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs[this.item.referance].files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove() {
      this.files = null;
      this.$refs[this.item.referance].value = "";
    },
    // generateURL(file) {
    //   if (file) {
    //     let fileSrc = URL.createObjectURL(file);
    //     setTimeout(() => {
    //       URL.revokeObjectURL(fileSrc);
    //     }, 1000);
    //     return fileSrc;
    //   }
    //   return "";
    // },
  },
};
</script>
