import axios from 'axios';
import store from '../store'; 
import router from '../router'

const instance = axios.create();
instance.interceptors.request.use(
    config => {
      const token = store.state.token
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );
  
  // Response interceptor
  instance.interceptors.response.use(response => response,
    async error => {
  
      // Eğer response 401 ise, kullanıcıyı anasayfaya yönlendir
      if (error.response.status === 401) {
        store.commit('signOut')
        router.push('/')
        // Orijinal isteği gerçekleştiren kodun hata bloğunu çalıştırmamak için,
        // başarılı bir yanıt döndürün
      }
      return Promise.reject(error);
    }
  );

  export default instance;