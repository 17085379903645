<template>
  <div class="flex flex-col items-center justify-between h-full">
    <div>
      <h2 class="text-xl font-bold mb-4 text-white">
        {{ question.question }}
      </h2>
      <div
        class="grid grid-cols-2 gap-3 sm:gap-4 md:gap-5 md:flex md:space-x-5 items-center md:space-y-0"
      >
        <answerButton
          v-for="(answer, answerIndex) in question.answers"
          :key="answerIndex"
          @save="submitAnswer(answer)"
          :text="answer"
          :button-class="[
            'outline',
            'outline-1',
            'font-bold',
            'py-2',
            'px-8',
            'rounded',
            'bg-transparent',
            answer !== selectedAnswers[questionIndex]
              ? ['bg-blue-800/20', 'hover:bg-blue-700', 'text-white']
              : [],
            answer === selectedAnswers[questionIndex]
              ? ['bg-blue-700', 'text-white']
              : [],
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import answerButton from "@/components/button.vue";

export default {
  name: "questionsComponent",
  components: {
    answerButton,
  },
  props: {
    question: {
      type: Object,
      default: () => {},
    },
    questionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedAnswers: [],
    };
  },
  computed: {
    ...mapState(["activeExamName"]),
  },
  methods: {
    submitAnswer(selectedAnswer) {
      this.$set(this.selectedAnswers, this.questionIndex, selectedAnswer);
      this.$emit("answer-submitted", {
        examName: this.activeExamName,
        answer: selectedAnswer,
        questionIndex: this.questionIndex,
      });
      console.log(this.selectedAnswers);
    },
  },
};
</script>
