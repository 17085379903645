<template>
  <div class="w-full flex flex-col justify-center items-center">
    <div class="flex space-x-2">
      <div class="box box1 bg-gray-50 w-4 h-4"></div>
      <div class="box box2 bg-gray-50 w-4 h-4"></div>
      <div class="box box3 bg-gray-50 w-4 h-4"></div>
      <div class="box box4 bg-gray-50 w-4 h-4"></div>
      <div class="box box5 bg-gray-50 w-4 h-4"></div>
    </div>
    <p class="mt-4 text-xl font-bold text-gray-100" v-if="text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "loading-custom",
  props: ["load", "text"],
};
</script>
<style scoped>
.box {
  animation: boxAnimation 1.2s ease-in-out infinite;
}

.box1 {
  animation-delay: 0s;
}

.box2 {
  animation-delay: 0.2s;
}

.box3 {
  animation-delay: 0.4s;
}

.box4 {
  animation-delay: 0.6s;
}

.box5 {
  animation-delay: 0.8s;
}

@keyframes boxAnimation {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
</style>
