<template>
  <div id="app" class="bg-[#071238] min-h-screen transition-all ease-in-out ">
    <checkInternet
      successText="İnternet Bağlantınız Tekrar Sağlandı"
      errorText="İnternet bağlanıtınız Koptu"
    />

    <navbar v-if="$route.name != 'notFound'" />
    <router-view class="min-h-screen-24 flex items-center justify-center  " />
  </div>
</template>
<script>
import navbar from "@/partitions/navbar.vue";
import checkInternet from "check-to-network";

export default {
  components: {
    navbar,
    checkInternet,
  },
  mounted() {
    this.$store.commit("setDateSelected", false);
    this.$store.commit("setInterviewDone", false);
  },
};
</script>
