<template>
  <div class="flex justify-center items-center">
    <div class="text-center" align="center">
      <div class="demo1">
        <div class="ui-error">
          <svg
            viewBox="0 0 87 87"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="Group-2" transform="translate(2.000000, 2.000000)">
                <circle
                  id="Oval-2"
                  stroke="rgba(252, 191, 191, .5)"
                  stroke-width="4"
                  cx="41.5"
                  cy="41.5"
                  r="41.5"
                ></circle>
                <circle
                  class="ui-error-circle"
                  stroke="#F74444"
                  stroke-width="4"
                  cx="41.5"
                  cy="41.5"
                  r="41.5"
                ></circle>
                <path
                  class="ui-error-line1"
                  d="M22.244224,22 L60.4279902,60.1837662"
                  id="Line"
                  stroke="#F74444"
                  stroke-width="3"
                  stroke-linecap="square"
                ></path>
                <path
                  class="ui-error-line2"
                  d="M60.755776,21 L23.244224,59.8443492"
                  id="Line"
                  stroke="#F74444"
                  stroke-width="3"
                  stroke-linecap="square"
                ></path>
              </g>
            </g>
          </svg>
        </div>
      </div>

      <p class="mt-10 w-8/12 mx-auto text-gray-300 text-center text-base">
        Bize zaman ayırdığınız ve bize sizi tanıma fırsatı verdiğiniz için çok
        teşekkür ederim. Diğer adaylarımız ile de görüşme sürecimizin
        tamamlanmasının ardından sizlere en kısa sürede bilgilendirme yapacağız.
        İyi günler dilerim.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "ressumeStorageTime",
  data() {
    return {
      errorMessage: "deneme",
      isTokenApproved: false,
      load: false,
    };
  },
};
</script>

<style>
.demo1 {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui-error {
  width: 100px;
  height: 100px;
  margin: 40px;
  /* border:1px solid #eee; */
}

.ui-error-circle {
  stroke-dasharray: 260.75219024795285px, 260.75219024795285px;
  stroke-dashoffset: 260.75219024795285px;
  animation: ani-error-circle 1.2s linear;
}

.ui-error-line1,
.ui-error-line2 {
  stroke-dasharray: 54px 55px;
  stroke-dashoffset: 55px;
  stroke-linecap: round;
}

.ui-error-line1 {
  animation: ani-error-line 0.15s 1.2s linear both;
}

.ui-error-line2 {
  animation: ani-error-line 0.2s 0.9s linear both;
}

@keyframes ani-error-line {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes ani-error-circle {
  0% {
    stroke-dasharray: 0, 260.75219024795285px;
    stroke-dashoffset: 0;
  }
  35% {
    stroke-dasharray: 120px, 120px;
    stroke-dashoffset: -120px;
  }
  70% {
    stroke-dasharray: 0, 260.75219024795285px;
    stroke-dashoffset: -260.75219024795285px;
  }
  100% {
    stroke-dasharray: 260.75219024795285px, 0;
    stroke-dashoffset: -260.75219024795285px;
  }
}
</style>
