<template>
  <div class="bg-[#071238] transition-all ease-in-out">
    <div class="relative w-full h-full">
      <!-- <errorSection v-if="isSafari" /> -->
      <startBtn />
      <input type="number" @input="setTimerLength($event.target.value)" />
      <!-- <startBtn v-else-if="!load && !isSafari" /> -->
      <!-- <loadingItem v-else-if="!isSafari && load" :load="load" /> -->
    </div>
    <!-- <tour :steps="steps" v-if="!load && !isSafari" /> -->
  </div>
</template>

<script>
import startBtn from "./components/startBtn.vue";

export default {
  name: "assestmentStart",
  components: {
    startBtn,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
