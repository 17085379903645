var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-4 sm:px-6 md:px-8 lg:px-10 pt-10 flex flex-col justify-between text-white min-h-screen-24"},[_c('div',{staticClass:"flex items-center sm:flex-row"},[_c('div',{staticClass:"flex items-center space-x-2 sm:space-x-4 md:space-x-6"},[_c('img',{staticClass:"w-[70px] sm:w-[75px] md:w-[95px] p-2 sm:p-3 md:p-4 mr-1 sm:mr-2 md:mr-2 border-2 rounded-lg",attrs:{"src":_vm.activeSvg.svg,"alt":"Exam Icon"}}),_c('span',{staticClass:"text-xl sm:text-2xl md:text-3xl font-bold text-white"},[_vm._v(" "+_vm._s(_vm.activeExamName)+" ")])]),_c('ul',{staticClass:"h-full overflow-auto flex items-center mt-2 ml-5 sm:hidden"},_vm._l((_vm.svgList),function(svg,index){return _c('li',{key:index,staticClass:"text-lg sm:text-xl md:text-xl p-1 ml-1 sm:ml-2 md:ml-4 rounded-lg",class:{ active: svg.name === _vm.activeExam.svgName }},[_c('span',{staticClass:"text-white"},[_c('img',{staticClass:"border-2 border-white rounded-xl p-1 w-[40px]",style:({
              backgroundColor:
                svg.name === _vm.activeExam.svgName ? '#2D8CFF40' : '',
            }),attrs:{"src":svg.svg,"alt":"Exam Icon"}})])])}),0)]),_c('div',{staticClass:"grid grid-cols-6 mt-3 sm:mt-4 md:mt-5 h-3/4 flex-grow"},[_c('div',{staticClass:"col-span-1 hidden sm:inline-block"},[_c('ul',{staticClass:"flex flex-col h-full overflow-auto"},_vm._l((_vm.svgList),function(svg,index){return _c('li',{key:index,staticClass:"text-lg sm:text-xl md:text-xl p-1 sm:p-2 md:p-2 ml-2 sm:ml-3 md:ml-4 rounded-lg",class:{ active: svg.name === _vm.activeExam.svgName },on:{"click":function($event){return _vm.changeActiveExam(svg.name)}}},[_c('span',{staticClass:"text-white"},[_c('img',{staticClass:"border-2 border-white rounded-xl p-1 w-[30px] sm:w-[35px] md:w-[42px]",style:({
                backgroundColor:
                  svg.name === _vm.activeExam.svgName ? '#2D8CFF40' : '',
              }),attrs:{"src":svg.svg,"alt":"Exam Icon"}})])])}),0)]),_c('div',{staticClass:"col-span-6 sm:col-span-5 md:col-span-5 lg:col-span-4 flex-auto h-full"},[_c(_vm.activeExam.component,{ref:_vm.activeExam.component,tag:"component",staticClass:"h-full w-full sm:h-auto sm:w-auto transition-all ease-in-out",attrs:{"steps":_vm.activeExam.steps,"currentStep":_vm.activeStep,"activeQuestionIndex":_vm.activeQuestionIndex,"activeExam":_vm.activeExam},on:{"answer-submitted":_vm.handleAnswerSubmitted}})],1)]),_c('div',{staticClass:"p-2 flex flex-col justify-between space-y-3"},[(_vm.paginationShow)?_c('QuestionsPagination',{attrs:{"questionCount":_vm.currentExamQuestionCount,"questionIndex":_vm.activeQuestionIndex,"changeActiveQuestion":_vm.changeActiveQuestion},on:{"change-question-index":_vm.changeActiveQuestion}}):_vm._e(),_c('div',{staticClass:"flex justify-between"},[_c('div',{on:{"click":_vm.toggleDarkMode}},[_c('img',{staticClass:"border-2 border-white rounded-xl p-1 w-8 sm:w-9 md:w-10 bg-[#2D8CFF40] ml-2 sm:ml-3 md:ml-4",attrs:{"src":require("@/assets/assestments/icons/dark-mode.svg"),"alt":"Dark and Light"}})]),_c('div',{staticClass:"w-full justify-center items-center space-y-3 sm:space-y-4 md:space-y-5"},[_c('div',{staticClass:"h-px bg-gradient-to-r from-transparent via-white to-transparent"}),_c('div',{staticClass:"flex justify-between w-full"},[_c('div'),_c('p',{staticClass:"text-xs sm:text-sm md:text-base text-white text-center w-3/4"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Not:")]),_vm._v(" "+_vm._s(this.activeNote.note)+" ")]),_c('asyncButton',{attrs:{"button-class":"w-full sm:w-auto hover:bg-blue-600/50\n          text-white font-bold py-2 px-4 ","text":_vm.activeStep === _vm.activeExam.steps
                ? 'Sonraki Sınav'
                : 'Sorulara Geç'},on:{"save":this.handleClick}})],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }