<template>
  <div>
    <v-tour name="myTour" :steps="steps" :options="myOptions"></v-tour>
  </div>
</template>

<script>
export default {
  name: "my-tour",
  props: ["steps"],
  data() {
    return {
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Eğitimi Bitir",
          buttonPrevious: "Öncesi",
          buttonNext: "Sonraki",
          buttonStop: "Tamam",
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$tours["myTour"].start();
    });
  },
};
</script>

<style></style>
